<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Profile Settings
        </h1>
        <v-card class="px-4">
          <v-card-text>
            <v-form ref="profileForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="formData.first_name" :rules="[rules.required]" label="First Name *" maxlength="20" required />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="formData.last_name" :rules="[rules.required]" label="Last Name *" maxlength="20" required />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="formData.email" :rules="rules.email" label="E-mail *" required />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select v-model="formData.country"
                            hint="Країна"
                            :items="countries"
                            :rules="[rules.required]"
                            item-text="title"
                            item-value="code"
                            label="Country *"
                            persistent-hint
                            single-line
                            required
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select v-model="formData.city"
                            hint="Область"
                            :items="cities"
                            :rules="[rules.required]"
                            item-text="title"
                            item-value="code"
                            label="City *"
                            persistent-hint
                            single-line
                            required
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="formData.address1" label="Address 1" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="formData.address2" label="Address 2" />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="formData.post_code" label="Post Code" />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="formData.phone_number" :rules="[rules.required]" label="Phone Number *" required />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
        <v-btn x-large block :disabled="!valid" color="success" @click="submit">Update</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfileSettings',
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      user: 'user/getUser'
    }),
    selectedCountry () {
      return this.countries.find(r => r.code === this.user.country)
    },
    selectedCity () {
      return this.cities.find(r => r.code === this.user.city)
    }
  },
  data () {
    return {
      valid: false,
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        city: '',
        address1: '',
        address2: '',
        post_code: '',
        phone_number: ''
      },
      countries: [
        { title: 'Україна', code: 'ukraine' }
      ],
      cities: [
        { title: 'АР Крим', code: 'crimea' },
        { title: 'Вінницька', code: 'vinnitsia' },
        { title: 'Волинська', code: 'volin' },
        { title: 'Дніпропетровська', code: 'dnipropetrovsk' },
        { title: 'Донецька', code: 'donetsk' },
        { title: 'Житомирська', code: 'zhitomir' },
        { title: 'Закарпатська', code: 'zakarpattia' },
        { title: 'Запорізька', code: 'zaporizzha' },
        { title: 'Івано-Франківська', code: 'iveno-frankivsk' },
        { title: 'Київська', code: 'kyiv' },
        { title: 'Кіровоградська', code: 'kirovograd' },
        { title: 'Луганська', code: 'lugansk' },
        { title: 'Львівська', code: 'lviv' },
        { title: 'Миколаївська', code: 'mikolaiv' },
        { title: 'Одеська', code: 'odesa' },
        { title: 'Полтавська', code: 'poltava' },
        { title: 'Рівненська', code: 'rivne' },
        { title: 'Сумська', code: 'sumi' },
        { title: 'Тернопільська', code: 'ternopil' },
        { title: 'Харківська', code: 'harkiv' },
        { title: 'Херсонська', code: 'herson' },
        { title: 'Хмельницька', code: 'khmelnitsk' },
        { title: 'Черкаська', code: 'cherkasi' },
        { title: 'Чернівецька', code: 'cernivtsi' },
        { title: 'Чернігівська', code: 'chernihiv' },
        { title: 'місто Севастополь', code: 'sevastopol_city' },
        { title: 'місто Київ', code: 'kyiv_city' },
      ],
      rules: {
        required: value => !!value || 'Required',
        matches: value => this.user.password === value || 'Password and confirmation must match',
        min: v => (v && v.length >= 8) || 'Min 8 characters',
        email: [
          v => !!v || 'Required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      updateProfileInformation: 'user/updateProfileInformation',
      pushMessage: 'ui/pushMessage'
    }),
    submit () {
      if (this.$refs.profileForm.validate()) {
        this.updateProfileInformation({
          token: this.token,
          payload: this.formData
        })
          .then(() => {
            this.pushMessage({
              status: 'success',
              message: 'Your account information is updated!',
              delay: 2000
            })
          })
      }
    }
  },
  mounted () {
    this.formData = this.user
  }
}
</script>
